<template>
  <div class="Policy">
    <p class="top_title">RealPlayer播放器隐私政策</p>
    <p class="content">最后修改日期：2022年10月10日</p>
    <p class="content">RealNetworks承诺会保护您个人信息的隐私权。本隐私政策适用于北京瑞尔视科技有限公司及其关联公司（简称“RealNetworks”或“我们”）。其适用于显示或提及本政策之我方产品、服务和网站，但不适用于任何显示或提及不同隐私政策的产品。本隐私政策说明了当您使用RealNetworks的“RealPlayer播放器”产品、服务及网站与我们进行互动时，RealNetworks收集、存储、使用、传输和披露您的个人信息的方式和目的。同时还说明了您对您的个人信息享有查阅、复制、转移、更正、补充、删除、解释说明等权利，以及您有权对您的个人信息如何使用作出某些选择。</p>
    <p class="content">本隐私政策涵盖了我们的线上和线下数据采集活动，包括我们通过多种渠道，例如网站、应用程序（APP）、小程序、电商渠道、第三方社交网络和各种活动等采集到的个人信息。请注意，我们可能会将不同来源（例如网站、线上活动等）收集的个人信息进行整合。为此，我们会将原本由瑞尔视公司不同实体或合作伙伴手机的个人信息整合到一起。如果您想知道如何拒绝该项处理，请参考第9节了解更多信息。</p>
    <p class="content">如果您未能向我们提供必要的个人信息（在这种情况下我们会提示您，例如，在我们的登记表格中明示此信息），我们可能无法向您提供我们的产品和/或服务。本隐私政策会不时修订（请参阅第10节）。</p>
    
    
    <p class="title">1.我们收集哪些个人信息？</p>
    <p class="content">RealNetworks会收集必要信息，以便有效营运、提供并增进我们推出的产品与服务、与您沟通，进而提供有效的客户支持。此类信息可包括：</p>
    <ul>
      <li>您的姓名与联络详细信息，例如电子邮件地址、邮寄地址、社交网络信息以及电话号码；</li>
      <li>账户登录信息，例如您的登录用户名/电子邮件地址、显示名称、密码和/或安全提示问题和答案；</li>
      <li>帐单信息，例如购买时使用的借记卡或信用卡信息（持卡人姓名、卡号、失效日期等）；</li>
      <li>人口统计信息，例如您的性别、年龄、喜爱的产品功能或语言偏好设定；</li>
      <li>您与我们网站、应用程序（APP）或小程序互动的相关信息，例如在您访问期间所浏览的页面内容、时长和日期，以及点选或滚动的广告主题；</li>
      <li>您使用我们产品和服务的相关信息，例如地理位置信息以及您使用RealPlayer播放器下载的内容（包含与该内容相关的网域）；</li>
      <li>您上传至RealPlayer播放器； </li>
      <li>您的电脑或移动设备的相关信息，例如：专属设备ID（永久性/非永久性、MAC或IMEI、设备序列号等）、硬件、软件、平台、浏览器类型，以及互联网协议（IP）地址；</li>
      <li>您自愿与我们分享的关于您使用我们的RealPlayer播放器产品和服务的任何体验的信息。 /非永久性、MAC或IMEI）、硬件、软件、平台、浏览器类型，以及互联网协议（IP）地址；</li>
      <table>
      <tr>
        <th width="24%">权限使用</th>
        <th>用途</th>
      </tr>
      
      <tr>
        <td>收集“运行中的进程信息”</td>
        <td>保障业务稳定，减少APP崩溃，预防恶意程序及反作弊，并对错误异常信息监控，并对软件进行优化升级</td>
      </tr>
      <tr>
        <td>收集“已安装APP信息”</td>
        <td>保障业务稳定，预防恶意程序及反作弊</td>
      </tr>
      <tr>
        <td>读取存储空间</td>
        <td>用于读取播放您设备上的媒体内容</td>
      </tr>

      <tr>
        <td>写入删除存储空间</td>
        <td>用于您设备上媒体内容的写入或删除</td>
      </tr>

      <tr>
        <td>收集设备信息（包括IMEI，MEID，Android_ID，IMSI，GUID，MAC地址，设备序列号等）</td>
        <td>提高服务安全性、保障运营质量及效率，为实现运行故障报错及分析和解决您使用RealPlayer过程中遇到的问题</td>
      </tr>
      
      <tr>
        <td>获取GPS定位</td>
        <td>用于汇总分析网站、广告网站等统计数据，以便持续优化您的使用体验</td>
      </tr>

      <tr>
        <td>获取WiFi定位</td>
        <td>用于汇总分析网站、广告网站等统计数据，以便持续优化您的使用体验</td>
      </tr>
    </table>
    
    
    
    </ul>
    <p class="content">虽然此类信息大部分都是直接从您或您的设备那里取得，例如您注册服务时提供的信息，或是您使用我们的产品或服务时我们所收集的信息，但我们有时也会收到来自第三方的信息，例如：</p>
    <ul>
      <li>和我们一起提供联合品牌服务或参与联合营销活动的合作伙伴；</li>
      <li>共享关于您的体验或您和其产品互动之信息的广告商；</li>
      <li>协助我们根据IP位址来判断您的设备位置，以便为您的位置自定义特定产品的服务供应商；</li>
      <li>向其他人公开显示使用者信息的社交网络（如微信和微博）和类似的第三方服务；以及</li>
      <li>公开可用的来源。</li>
    </ul>
    <p class="content">举例来说，当您通过微信等社交网站（简称“第三方社交网站”）来使用RealPlayer播放器产品时，即表示您允许RealNetworks存取您向此类第三方社交网站提供的某些信息，例如您用于该产品或服务的使用者ID、名字和姓氏、电子邮件地址、性别、生日、当前位置、朋友列表、个人档案图片及使用方式数据。通过第三方社交网站存取RealPlayer播放器产品或服务，即表示您授权RealNetworks收集、存取及使用您同意第三方社交网站通过第三方社交网站向RealNetworks提供的信息。RealNetworks收集、储存及使用信息的方式受本隐私政策规范。RealNetworks可能会存取您在第三方社交网站上公开分享的视频、照片及其他媒介，并根据该第三方社交网站的条款与政策使用此类数据。如果您不希望RealNetworks存取此类数据，请调整您的第三方社交网站隐私权设定，不要公开分享媒介内容。</p>
    <p class="content">以公开可用来源为例，RealNetworks会收集知名名人和公众人物的公开可用图像。RealNetworks会使用这些图像来为其名人数据库开发相关的脸部识别数据，此数据库支持RealPlayer与Chrome扩充功能StarSearch by Real中的名人识别功能。</p>
    <p class="content">我们会依据本隐私政策所述的实施条例以及数据源所施加的任何额外限制来保护从第三方取得的数据。当系统要求提供个人信息时，您可以选择拒绝。但如果您对提供服务所用之必要信息，选择不提供，可能就无法使用该服务。</p>
    <p class="content">我们的服务并非以儿童为目标，且RealNetworks不会刻意或故意向未满14岁的儿童收集个人信息。</p>
    <p class="content">在此我们特别提示您，我们仅会在您实现明确同意的情况下处理您提供给我们的某些敏感个人信息，包括：银行账户、身份证件号码、通信记录和内容、定位信息、行踪轨迹、住址、交易信息、14岁以下（含）儿童的个人信息、个人健康胜利信息等，用于用户服务及市场营销目的等。如果我们处于其他目的处理您的敏感个人信息，我们将给予以下法律依据：（1）发现和预防犯罪（包括防止欺诈）；（2）遵守使用法律。</p>
    <p class="title">2.我们如何使用个人信息？</p>
    <ul>
      <li>RealNetworks使用我们收集的个人信息来进行运营并提供产品与服务，包括：</li>
      <li>向您提供RealPlayer播放器产品与服务并据此收费；</li>
      <li>检查针对优质内容或其他访问受限的服务与软件的访问权限；</li>
      <li>判断您的设备是否满足使用本公司产品的最低系统需求，并提供和兼容性及互通性有关的信息；</li>
      <li>自动更新下载的产品；</li>
      <li>提供语言及地点的自定义功能，提供内容建议或说明与指导；</li>
      <li>制作汇总统计数据，以协助我们深入了解客户对本公司产品的使用方式并将其用于产品营销；</li>
      <li>进行研究、开发新产品或功能，以及改进我们现有的软件和服务，例如修正问题与持续提高功能的性能，例如脸部辨识或内容建议；</li>
      <li>为了向第三方供应商支付版税及授权费用而追踪内容的使用；并且</li>
      <li>与您沟通，例如传送给您关于帐号及客户服务事宜的消息；邀请您参加意见调查；以及传递新闻、更新、针对目标消费群的广告、促销和特殊优惠活动。</li>
    </ul>
    <p class="title">3.我们如何维护个人信息的安全？</p>
    <p class="content">RealNetworks承诺会保护您的个人信息。我们运用下列管控措施来保护您的个人信息：</p>
    <ul>
      <li>从网络浏览器传输信息到RealNetworks时，使用SSL安全联机来保护信息；</li>
      <li>采取安全控制措施，限制访问存有个人信息的数据库；</li>
      <li>对个人敏感信息（例如：信用卡号码、使用者名称）进行加密；</li>
      <li>对员工访问包含个人信息的数据库的行为予以限制，并要求访问这类信息的员工遵守保密规定；以及</li>
      <li>透过合约、技术与组织方式来限制转包商，以遵照执行RealNetworks隐私政策或相等之隐私权准则。</li>
    </ul>
    <p class="title">4.我们保留个人信息多久？</p>
    <p class="content">我们只会将个人信息保留一段时间，以完成此隐私政策所述目的，例如提供服务及完成您要求的交易；或其他必要目的，例如遵守本公司法律义务、解决纠纷及执行协议。完成上述目的后系统会将信息删除或去标识化。当您在本公司服务中移除内容，或距离您上一次使用服务后期满五年（以两者之间先发生者为准），您的内容将会从服务中删除。不过从其中取得的特定去标识化或汇总数据仍会留作限定用途，例如产品改进。 </p>
    <p class="title">5.我们会在何时以何种方式与第三方共享个人信息？</p>
    <p class="content">未经您的同意，我们不会与第三方共享您的个人信息，但本隐私政策中所述之规定不在此限。为有效推行我们的服务并提供最佳的客户体验，我们会与下列合作伙伴共享个人信息。</p>
    <p class="content">服务供应商：</p>
    <p class="content">为使服务供应商向RealNetworks提供特定服务，RealNetworks可能会对服务供应商透露客户的个人信息。例如，RealNetworks会与第三方支付处理单位共享个人信息，以作为收费用途，以及与第三方数据中心服务供应商共享信息，以取得安全储存空间。RealNetworks的服务供应商有义务为其处理的所有个人信息保密。</p>
    <p class="content">资料分析供应商：</p>
    <p class="content">RealNetworks也会透过第三方数据分析供应商来协助我们汇编关于公司网站运营及性能的汇总统计数据。数据分析供应商会在您的设备上设定或读取其专属cookie或其他识别码，借此收集您在本公司及其他公司网站的使用信息。您可以访问第三方数据分析商的隐私政策以深入了解相关规定，包括如何退出参与。</p>
    <p class="content">广告公司： </p>
    <p class="content">我们会与第三方广告公司合作，以协助确保您对所看到的商品和服务广告可能会有较大的兴趣。目前这类第三方合作伙伴。与我们合作的这些广告公司一般在您访问此网站或其他网站时，会使用第三方cookie和/或网络信标来收集专属ID号码并结合其他信息，例如点选串流信息、浏览器类型、时间与日期、点选或滚动的广告标题。RealPlayer电脑应用程序可能会将第三方广告公司的 Cookie从您的网页浏览器复制到应用程序的内嵌浏览器，以展现一致的浏览体验。请检阅我们的「Cookie的使用」一节以了解我们如何使用Cookie以及您该如何变更Cookie设定。/或网络信标来收集专属ID号码并结合其他信息，例如点选串流信息、浏览器类型、时间与日期、点选或滚动的广告标题。RealPlayer电脑应用程序可能会将DoubleClick Cookie从您的网页浏览器复制到应用程序的内嵌浏览器，以展现一致的浏览体验。请检阅我们的「Cookie的使用」一节以了解我们如何使用Cookie以及您该如何变更Cookie设定。</p>
    <p class="content">在移动设备方面，这些广告公司有时候会搭配由移动操作系统提供的广告ID（例如Android广告ID和/或Apple广告ID），收集并使用此类Cookie。这些广告ID可以透过您的移动操作系统设定加以控制或重新设定。设定会依设备而异，但是您通常可于设备设定的「隐私权」或「广告」下面找到广告标识。</p>
    <p class="content">当您在RealNetworks网站上搜寻特定内容时，搜寻字词可能会内嵌在「搜寻字串」中，而字串会出现在网页浏览器的URL显示视窗内。某些广告服务或第三方内容提供者可能会将这些搜寻字串与他们所设定或撷取自我们网页的cookie建立关联。</p>

    <p class="title">6.第三方合作伙伴</p>
    <p class="content">在某些情况下，存取某些产品与服务可能需要向第三方提供个人信息。如为这种情形，我们将在注册程序中，通过分发流程或其他类似方式针对使用条款进行说明，该等第三方对于此类信息的使用受其隐私政策管控。请参阅第三方的隐私政策，确定您了解他们如何使用您提供的个人信息。如果您不想传输此类信息，则不应该注册相应的产品或服务。此外，某些内容供应商与著作权所有者可能会要求RealNetworks提供特定的存取信息与使用信息，作为内容授权之条件。</p>
    <p class="content">另外，RealNetworks网站可能会连接到第三方网站或服务。某些RealNetworks产品可能新增第三方外挂程序。本隐私政策不适用于任何此类的第三方网站、服务或外挂程序。</p>
    <p class="content">RealNetworks可能会将个人信息与RealNetworks及/或其子公司之全部或部分业务所涉及的销售、并购、转让、交换或其他处理（不论其属于资产、股票或其他类别）一并转移。</p>
    <p class="content">如基于法律规定，或RealNetworks善意相信为达成以下目的必须披露您的个人信息，RealNetworks也可能会披露您的个人信息，包括您使用RealNetworks产品或服务所上传的文件内容：（a）遵守法律规定；（b）遵守在任何司法管辖区内主管司法机关的命令；（c）遵守RealNetworks的合法程序；（d）保护及捍卫RealNetworks的权利或财产；或（e）于紧急情况采取行动以保护RealNetworks产品使用者或公众的人身安全。</p>
    <p class="content">涉及嵌入第三方代码、插件传输个人信息的情形逐项举例：</p>
    <p class="content"><b>1）巨量引擎SDK</b></p>
    <table>
      <tr>
        <th width="24%">权限使用</th>
        <th>用途</th>
      </tr>
      
      <tr>
        <td>收集"运行中的进程信息"</td>
        <td>保障业务稳定，减少APP崩溃，预防恶意程序及反作弊，并对错误异常信息监控，并对软件进行优化升级</td>
      </tr>

      <tr>
        <td>收集“已安装APP信息”</td>
        <td>保障业务稳定，预防恶意程序及反作弊</td>
      </tr>

      <tr>
        <td>收集设备信息（包括IMEI，MEID，Android ID，IMSI，GUID， MAC地址，设备序列号等）</td>
        <td>用于统计、分析数据，提供基础反作弊能力、设备应用性能监控服务</td>
      </tr>
      
      <tr>
        <td>获取GPS定位</td>
        <td>用于汇总分析网站、广告网站等统计数据，以便持续优化您的使用体验</td>
      </tr>

      <tr>
        <td>获取WiFi定位</td>
        <td>用于汇总分析网站、广告网站等统计数据，以便持续优化您的使用体验</td>
      </tr>
    </table>
    <p class="content">  SDK服务提供商：北京巨量引擎网络技术有限公司</p>
    <p class="content">  SDK服务提供商隐私政策链接：https://www.oceanengine.com/extra/legal</p>

    <p class="content"><b>2）优量汇SDK</b></p>
    <table>
      <tr>
        <th width="24%">权限使用</th>
        <th>用途</th>
      </tr>
      
      <tr>
        <td>收集"运行中的进程信息"</td>
        <td>保障业务稳定，减少APP崩溃，预防恶意程序及反作弊，并对错误异常信息监控，并对软件进行优化升级</td>
      </tr>

      <tr>
        <td>收集“已安装APP信息”</td>
        <td>保障业务稳定，预防恶意程序及反作弊</td>
      </tr>

      <tr>
        <td>收集设备信息（包括IMEI，MEID，Android ID，IMSI，GUID， MAC地址，设备序列号等）</td>
        <td>用于统计、分析数据，提供基础反作弊能力、设备应用性能监控服务</td>
      </tr>
      
      <tr>
        <td>获取GPS定位</td>
        <td>用于汇总分析网站、广告网站等统计数据，以便持续优化您的使用体验</td>
      </tr>

      <tr>
        <td>获取WiFi定位</td>
        <td>用于汇总分析网站、广告网站等统计数据，以便持续优化您的使用体验</td>
      </tr>
    </table>
    <p class="content">  SDK服务提供商：腾讯科技(深圳)有限公司</p>
    <p class="content">  SDK服务提供商隐私政策链接：https://www.tencent.com/zh-cn/privacy-policy.html</p>

    <p class="title">7.COOKIE 的使用</p>
    <p class="content">有关RealNetworks和我们的业务合作伙伴如何使用Cookie和类似技术的更多信息，请参阅RealNetworks的 Cookie 政策。</p>
    <p class="content">与许多网络和电子商务网站一样，RealNetworks和我们的业务合作伙伴使用cookie和类似技术来运营我们的网站和服务，并为您提供量身定制的体验。“cookie”是一个小文本文件，由网络浏览器存储在计算机或移动设备上。当您访问我们的网站并使用我们的产品和服务时，RealNetworks Cookie会自动发送到我们的服务器。</p>
    <p class="content">Cookie允许我们的网站和在线服务“识别”您的设备，并显示与您相关的信息。当您返回RealNetworks网站时，您的设置将得到维护，我们可能对您访问RealNetworks网站并使用RealNetworks产品和服务时看到的内容、横幅、广告和促销进行设置。这些技术还使我们能够缓存RealNetworks网站和服务的一些元素，以提高其性能。下表描述了我们使用的cookie类型：</p>

    
    <table>
      <tr>
        <th width="20%">Cookie类型</th>
        <th>目的</th>
      </tr>

      <tr>
        <td>功能性质的</td>
        <td>识别用户偏好（玩网络游戏或下载游戏等），并记住订阅者和注册账户持有人的信息，如用户名或ID。</td>
      </tr>

      <tr>
        <td>广告性质的</td>
        <td>这些是RealNetworks或我们的合作伙伴出于广告目的放置的。当您回到我们的网站，提供对目标消费群的广告和促销。</td>
      </tr>

      <tr>
        <td>分析性质的</td>
        <td>开发关于访问者如何使用我们网站的汇总统计数据，包括该网站的访问者数量、访问者来自何处以及他们访问的页面。这些cookies帮助我们了解RealNetworks网站上的流量模式，这样我们就可以改善客户的体验，并提供更容易找到相关内容和信息的功能。</td>
      </tr>
    </table>

    <p class="content">大多数浏览器还允许您更改cookie设置。这些设置通常可以在浏览器的“选项”或“首选项”菜单中找到。你也可以使用插件来帮助你保存你的选择退出cookies。欲了解更多信息，请访问http://www.aboutads.info/PMC.关于如何管理或禁用基于web的Cookie，我们提供了许多指南，包括：www.aboutcookies.org和www.allaboutcookies.org。</p>
    <p class="content">RealNetworks不要求您接受Cookie，但是，如果您拒绝接受Cookie，我们网站、产品和服务上的某些功能可能会受损。例如，如果禁用Cookie，我们的产品购买签出流程将无法正常运行。</p>
    <p class="content">我们通过cookies收集或存储的任何个人信息都将根据本协议和我们的隐私政策进行处理。</p>
    <p class="content">除了由我们控制的域放置的cookie之外，一些RealNetworks属性还托管由我们不拥有的域放置的cookie。如果您的浏览器设置允许，这些“第三方”cookie或其他技术可以测量网站流量的统计数据，还可以让我们的广告合作伙伴提供更多定制体验。我们的隐私政策中描述了我们的第三方广告合作伙伴收集和使用信息的情况。</p> 
    <p class="title">8.如何管理来自REALNETWORKS 的电子邮件通知</p>
    <p class="content">为了提供更好的产品和服务，RealNetworks可能会向您发送电子邮件。您可以随时更改通信首选项，方法是：（a）在收到的邮件中选择“退出”选项；（b）如果您已使用RealNetworks创建了在线帐户，通过更改帐户设置以选择是否接收邮件。请注意，单独的RealNetworks产品、服务和业务线可能会向您发送单独的实时通讯或电子邮件，您可能需要单独管理。有关您购买的产品或服务的事件或与RealNetworks产品或服务相关的技术服务问题，我们也可能会向您发送电子邮件。</p>
    <p class="title">9.如何访问、更新及删除您的个人信息</p>
    <p class="content">我们努力为您提供个人信息的自主权。因此，我们为您提供了几种访问个人信息的方法：（a）您可以使用位于https://realtimes.real.com/mydata上的查看我的数据工具选项来访问您的帐户信息、偏好和数据。您可以通过从RealPlayer（通常在“帮助”菜单下）或其他RealNetworks消费软件的菜单中选择“管理我的帐户”来访问此页面。（b） 您也可以通过使用您的姓名和密码登录以访问www.realplayer.cn网站上您的数据。（c） 您也可以通过单击此处提交信息请求（d）。如果您的图像和面部识别信息包含在我们的名人数据库中，您可以通过联系RealPlayer客服Celebrity-opt-out@realnetworks.com来提交删除信息的请求. </p>
    <p class="content">如果您忘记了用户名或密码，或者没有帐户，您可以使用我们的在线帮助资源，或者单击此处与我们联系。有关联系我们的其他方式，请参阅“联系我们-关于隐私”部分。</p>
    <p class="title">10.如何注册、注销删除您的账户信息</p>
    <p class="content"><b>1）注册账户</b></p>
    <p class="content">使用高级功能时，您需要成为注册用户。当您注册时，您需要至少向我们提供您准备使用的电子邮箱地址，您的账户名为您的电子邮箱地址，这些信息均属于您的“账户信息”。我们为您提供了几种注册账号的方法：（a）您可以访问https://realtimes.realplayer.cn/myaccount 点击“创建账户”按钮创建并注册新账户。（b）您也可以在RealPlayer播放器的应用端，进入/设置/账户/ 点击“创建账户”来创建并注册新账户。</p>
    <p class="content"><b>2）注销删除账户</b></p>
    <p class="content">请您知悉并理解，注销删除账号的行为是不可逆的行为，当您注销账号后，我们将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定的除外。我们为您提供了几种注销删除账号的方法：（a）您可以访问https://realtimes.realplayer.cn/mydata 点击“删除我的账户”来注销删除您的账户。(b) 您也可以在RealPlayer播放器的应用端，进入/设置/账户/ 点击“删除账户”来注销并删除账户。</p>
    <p class="title">11.隐私政策的修订</p>
    <p class="content">我们会根据需要修订本隐私政策，使其与RealNetworks产品、服务和网页保持同步。完成修订时我们将更新本政策顶部的日期。如果我们做出任何重大更改，我们将通过电子邮件（发送至您帐户中指定的电子邮件地址）或在此页面上发布公告通知您。我们建议您定期查看此页面，以了解有关我们隐私实践的最新信息。</p>
    <p class="title">12. 数据传输</p>
    <p class="content">原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</p>
    <p class="title">13. 向我们咨询隐私问题</p>
    <p class="content">如果您对本隐私政策、我们产品或服务的隐私方面、RealNetworks保留的与您有关的信息有任何疑问，或希望投诉，请通过以下地址与我们联系。</p>
    <p class="content">隐私政策部门</p>
    <p class="content">北京瑞尔视科技有限公司</p>
    <p class="content">北京市朝阳区工体北路甲2号盈科中心A座2116室</p>
    <p class="content">© 2022 RealNetworks 北京瑞尔视科技有限公司</p>
  </div>
</template>
<script>
  export default {
    name: 'Policy',
    created:function(){
        window.scrollTo(0,0);
    },
    methods:{
      
    }
  }
</script>
<style scoped>
  .Policy{width:60%;margin: 0 auto;}
  .top_title{font-size: 28px;color: #00a7e1;margin: 20px 0;font-weight: bold;}
  .title{font-size: 20px;font-weight: bold;margin: 10px 0;}
  .content,th,td{text-indent:2em;font-size: 14px;line-height: 28px;}
  ul{margin-left: 40px;}
  li{font-size: 14px;line-height: 28px;}
  table{border-top: 1px solid #333333;margin: 20px 0;border-left: 1px solid #333333;}
  th{font-size: 14px;}
  th,td{border-right: 1px solid #333;border-bottom: 1px solid #333;padding: 20px;}
</style>